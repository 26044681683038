import { useContext } from "react";
import { FaCode, FaEnvelope, FaGithub } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { LangContext } from "../Contexts/scrollContext";

export function Contact()
{
    const { lang, changeLang } = useContext( LangContext );

    const changelan = ( e: React.ChangeEvent<HTMLSelectElement> ) => 
    {
        changeLang( e.target.value );
    };

    
    

    const fr_component =  (

        <div id="contact" className="mb-5 lg:text-lg lg:ml-[4%] ml-2">
            
            <div className="lg:float-left lg:block flex justify-center ml-4 mr-[5%]">
                <img src="assets/pic-black-shirt.png" className="rounded-[128px/128px]" height="128" width="128" alt="" />
            </div>

            <div className="lg:ml-3 mt-2 text-white text-sm text-center lg:hidden block">
                <span className="text-primary_orange font-bold"> 
                    Web Developer Fullstack
                </span>
                <div>
                    <select name="lang" onChange={changelan} className="text-primary_orange bg-[#0f172a] rounded">
                        <option value="french"> Français </option>
                        <option value="english"> English </option>
                    </select>
                </div>
               
            </div>

            <div className="lg:block flex justify-center w-full">
                <div className="float-right lg:w-[44%] lg:mb-0 lg:mt-0 mt-4 mb-5">

                    <div className="text-white ">
                        <span className="text-primary_orange font-bold"> 
                            <FaCode className="float-left mt-1 mx-2"/>
                            Main Skill
                        </span>
                    </div>

                    <div className="ml-3 text-white ">
                        
                        <span className="mx-2 text-primary_orange"> 
                            Backend: 
                        </span>

                        <span className="text-sm">
                        TS/Node, PHP/Symfony/CI 
                        </span>
                    </div>

                    <div className="ml-3 text-white ">
                        <span className="mx-2 text-primary_orange"> 
                            Frontend: 
                        </span>

                        <span className="text-sm">
                            React, Angular, HTML/CSS/JS, TailwindCss
                        </span>
                    </div>

                    <div className="ml-3 text-white ">
                        <span className="mx-2 text-primary_orange"> 
                            Android: 
                        </span>

                        <span className="text-sm">
                            Kotlin
                        </span>
                    </div>

                    <div className="ml-3 text-white ">
                        
                        <span className="mx-2 text-primary_orange"> 
                            Other: 
                        </span>

                        <span className="text-sm">
                            Python ( for Task Automation and Pentesting)
                        </span>

                    </div>

                </div>
            </div>

            <div className="lg:block w-full">
                <div className="lg:ml-3 lg:mt-1 mt-4 lg:w-full ">

                    <div className="ml-3 mt-2 text-white lg:block hidden">
                        <span className="text-primary_orange font-bold"> 
                            Web Developer Fullstack
                        </span>
                        
                        <select name="lang" onChange={changelan} className="bg-primary_orange text-white ml-4 px-1 py-1 text-sm rounded">
                            <option value="french">French</option>
                            <option value="english">English</option>
                        </select>

                    </div>

                    <div className="ml-3 mt-2 text-white ">
                        <span className="mx-2 text-primary_orange"> 
                            <FaEnvelope className="float-left mt-1"/>Email : 
                        </span>
                        <a href="mailto:ramanantoaninatsilavina@gmail.com">
                        ramanantoaninatsilavina@gmail.com
                        </a>
                    </div>

                    <div className="ml-3 mt-2 text-white ">
                        <span className="mx-2 text-primary_orange"> 
                            <IoCall className="float-left mt-1"/>Tel : 
                        </span>
                        +261 32 14 931 02
                    </div>

                    <div className="ml-3 mt-2 text-white ">
                        <a href="https://github.com/mrxoder">
                            <span className="mx-2 text-primary_orange">
                                <FaGithub className="float-left mt-1" />Github : 
                            </span>
                        @mrxoder
                        </a>
                    </div>

                </div>
            </div>

        </div>
    );    
    
    
    return fr_component;
}