import { createContext } from "react";

export const ScrollContext = createContext({
    isScrolled: false,
    setTrue: () => {},
    setFalse: () => {}
});

export const LangContext = createContext({
    lang: "french",
    changeLang: ( value: string ) => {}
});
